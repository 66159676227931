import { useEffect, FormEventHandler, useState } from 'react';
import { Head, Link, useForm } from '@inertiajs/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Input } from '@/Components/ui/input';
import { Button } from '@/Components/ui/button';
import { Label } from '@/Components/ui/label';
import { Checkbox } from '@/Components/ui/checkbox';
import { useAnalytics } from '../../hooks/useAnalytics';

// Product showcase slides
const PRODUCT_SLIDES = [
    {
        title: "Reading Exam",
        backgroundColor: "bg-sky-300",
        content: (
            <div className="w-full flex flex-col justify-start gap-3">
                <div>
                    <div className="text-sm font-medium mb-1">Select the best title:</div>
                    <div className="text-sm font-light whitespace-normal mb-2">Eine neue Studie zeigt: Sport verbessert die Stimmung.</div>
                </div>
                <div className="flex flex-col gap-1.5">
                    <motion.div 
                        whileHover={{ x: 5 }}
                        className="w-full border-2 border-zinc-200 bg-white rounded-md p-1 px-2 text-xs cursor-pointer hover:bg-sky-50 transition-colors duration-200 hover:border-sky-500"
                    >
                        Gesundes Leben
                    </motion.div>
                    <motion.div 
                        whileHover={{ x: 5 }}
                        className="w-full border-2 bg-emerald-50 border-emerald-500 rounded-md p-1 px-2 text-xs cursor-pointer"
                    >
                        Sport und Gesundheit
                    </motion.div>
                </div>
            </div>
        )
    },
    {
        title: "Writing Exam",
        backgroundColor: "bg-yellow-300",
        content: (
            <div className="w-full flex flex-col justify-start gap-3">
                <div>
                    <div className="text-sm font-medium mb-1">Write an invitation to Berlin:</div>
                    <div className="text-sm font-light whitespace-normal mb-2">
                        Liebe Mark,<br />
                        Ich hoffe, dir geht es gut! 😊
                    </div>
                </div>
                <div className="flex flex-row text-xs gap-2">
                    <div className="bg-emerald-50 border-emerald-500 border-2 rounded-md p-1 px-2">
                       Correct Grammar
                    </div>
                    <div className="bg-red-50 border-red-500 border-2 rounded-md p-1 px-2">
                       Spelling Mistake
                    </div>
                </div>
            </div>
        )
    },
    {
        title: "Grammar Test",
        backgroundColor: "bg-purple-300",
        content: (
            <div className="w-full flex flex-col justify-start gap-3">
                <div>
                    <div className="text-sm font-medium mb-1">Complete the sentence:</div>
                    <div className="text-sm font-light whitespace-normal mb-2">
                        Gestern _____ ich mit meiner Freundin ins Kino gegangen.
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <motion.div 
                        whileHover={{ x: 5 }}
                        className="w-full border-2 border-zinc-200 bg-white rounded-md p-1 px-2 cursor-pointer hover:bg-purple-50 transition-colors duration-200 hover:border-purple-500"
                    >
                        bin
                    </motion.div>
                    <motion.div 
                        whileHover={{ x: 5 }}
                        className="w-full border-2 bg-emerald-50 border-emerald-500 rounded-md p-1 px-2 cursor-pointer"
                    >
                        habe
                    </motion.div>
                </div>
            </div>
        )
    }
];

interface Props {
    status?: string;
    canResetPassword: boolean;
}

interface FormData {
    email: string;
    password: string;
    remember: boolean;
}

export default function Login({ status, canResetPassword }: Props) {
    const { trackFormSubmission, trackPageView } = useAnalytics();
    const [currentSlide, setCurrentSlide] = useState(0);

    // Auto-rotate slides
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % PRODUCT_SLIDES.length);
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    const { data, setData, post, processing, errors, reset } = useForm<FormData>({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        trackPageView('login_page');
    }, []);

    const submit: FormEventHandler = (e) => {
        e.preventDefault();
        trackFormSubmission('login_form', { email: data.email });
        post(route('login'));
    };

    return (
        <div className="min-h-screen flex flex-col md:flex-row">
            {/* Left Column - Login Form */}
            <div className="w-full md:w-1/2 flex flex-col justify-center p-6 md:p-8 lg:p-12 bg-white">
                <div className="w-full max-w-sm mx-auto">
                    {/* Logo */}
                    <Link href="/" className="mb-8 inline-block">
                        <h1 className="text-xl font-bold text-gray-900">
                            GermanPrep
                        </h1>
                    </Link>

                    {/* Welcome Text */}
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-900">Welcome back</h2>
                        <p className="text-gray-600 mt-2">Please enter your details to sign in</p>
                    </div>

                    {status && (
                        <motion.div 
                            className="mb-4 text-sm font-medium text-green-600 bg-green-50 p-4 rounded-lg"
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                        >
                            {status}
                        </motion.div>
                    )}

                    <form onSubmit={submit} className="space-y-6">
                        <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                                id="email"
                                type="email"
                                value={data.email}
                                className="mt-1"
                                onChange={(e) => setData('email', e.target.value)}
                                autoFocus
                            />
                            {errors.email && (
                                <motion.p 
                                    className="mt-1 text-sm text-red-500"
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                >
                                    {errors.email}
                                </motion.p>
                            )}
                        </div>

                        <div>
                            <Label htmlFor="password">Password</Label>
                            <Input
                                id="password"
                                type="password"
                                value={data.password}
                                className="mt-1"
                                onChange={(e) => setData('password', e.target.value)}
                            />
                            {errors.password && (
                                <motion.p 
                                    className="mt-1 text-sm text-red-500"
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                >
                                    {errors.password}
                                </motion.p>
                            )}
                        </div>

                        <div className="flex items-center justify-between">
                            <label className="flex items-center gap-2">
                                <Checkbox
                                    checked={data.remember}
                                    onCheckedChange={(checked) => setData('remember', checked as boolean)}
                                />
                                <span className="text-sm text-gray-600">Remember me</span>
                            </label>

                            {canResetPassword && (
                                <Link
                                    href={route('password.request')}
                                    className="text-sm text-gray-600 hover:text-gray-900"
                                >
                                    Forgot password?
                                </Link>
                            )}
                        </div>

                        <Button
                            className="w-full"
                            disabled={processing}
                        >
                            {processing ? (
                                <motion.div
                                    className="inline-block"
                                    animate={{ rotate: 360 }}
                                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                >
                                    ⟳
                                </motion.div>
                            ) : (
                                'Sign in'
                            )}
                        </Button>

                        <p className="text-center text-sm text-gray-600">
                            Don't have an account?{' '}
                            <Link href={route('register')} className="font-medium text-gray-900 hover:text-gray-700">
                                Sign up
                            </Link>
                        </p>
                    </form>
                </div>
            </div>

            {/* Right Column - Product Showcase (Hidden on mobile) */}
            <div className="hidden md:flex md:w-1/2 bg-gray-50 items-center justify-center p-12">
                <div className="max-w-lg w-full">
                    <h2 className="text-2xl font-semibold mb-8">Everything you need to pass your B1 German exam</h2>
                    
                    {/* Product Showcase Slideshow */}
                    <div className="relative h-[450px] mb-8">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                className={`p-6 rounded-lg mx-auto w-full h-full ${PRODUCT_SLIDES[currentSlide].backgroundColor}`}
                            >
                                <div className="flex flex-col h-full">
                                    <h3 className="text-lg font-semibold mb-4">
                                        {PRODUCT_SLIDES[currentSlide].title}
                                    </h3>
                                    <div className="flex-1 bg-white/90 backdrop-blur-sm rounded-lg p-6 shadow-lg">
                                        {PRODUCT_SLIDES[currentSlide].content}
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>

                        {/* Slide Indicators */}
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
                            {PRODUCT_SLIDES.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setCurrentSlide(index)}
                                    className={`w-2 h-2 rounded-full transition-colors ${
                                        currentSlide === index ? 'bg-gray-800' : 'bg-gray-300'
                                    }`}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Feature List */}
                    <div className="space-y-4">
                        <motion.div 
                            className="flex items-center gap-3"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.1 }}
                        >
                            <div className="w-8 h-8 bg-sky-100 rounded-lg flex items-center justify-center">
                                📚
                            </div>
                            <div>
                                <h4 className="font-medium">Reading Practice</h4>
                                <p className="text-sm text-gray-600">Unlimited mock exams with instant feedback</p>
                            </div>
                        </motion.div>

                        <motion.div 
                            className="flex items-center gap-3"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.2 }}
                        >
                            <div className="w-8 h-8 bg-yellow-100 rounded-lg flex items-center justify-center">
                                ✍️
                            </div>
                            <div>
                                <h4 className="font-medium">AI Writing Review</h4>
                                <p className="text-sm text-gray-600">Get instant feedback on your writing</p>
                            </div>
                        </motion.div>

                        <motion.div 
                            className="flex items-center gap-3"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.3 }}
                        >
                            <div className="w-8 h-8 bg-purple-100 rounded-lg flex items-center justify-center">
                                🎯
                            </div>
                            <div>
                                <h4 className="font-medium">Grammar Mastery</h4>
                                <p className="text-sm text-gray-600">Perfect your grammar with targeted exercises</p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Mobile-only Feature Preview */}
            <div className="md:hidden w-full bg-gray-50 p-6">
                <div className="max-w-sm mx-auto">
                    <h2 className="text-xl font-semibold mb-4">Why Choose GermanPrep?</h2>
                    
                    {/* Simplified Features List for Mobile */}
                    <div className="space-y-3">
                        <motion.div 
                            className="flex items-center gap-3"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.1 }}
                        >
                            <div className="w-8 h-8 bg-sky-100 rounded-lg flex items-center justify-center">
                                📚
                            </div>
                            <div>
                                <h4 className="font-medium">Reading Practice</h4>
                                <p className="text-sm text-gray-600">Unlimited mock exams with instant feedback</p>
                            </div>
                        </motion.div>

                        <motion.div 
                            className="flex items-center gap-3"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.2 }}
                        >
                            <div className="w-8 h-8 bg-yellow-100 rounded-lg flex items-center justify-center">
                                ✍️
                            </div>
                            <div>
                                <h4 className="font-medium">AI Writing Review</h4>
                                <p className="text-sm text-gray-600">Get instant feedback on your writing</p>
                            </div>
                        </motion.div>

                        <motion.div 
                            className="flex items-center gap-3"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.3 }}
                        >
                            <div className="w-8 h-8 bg-purple-100 rounded-lg flex items-center justify-center">
                                🎯
                            </div>
                            <div>
                                <h4 className="font-medium">Grammar Mastery</h4>
                                <p className="text-sm text-gray-600">Perfect your grammar with targeted exercises</p>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}
